:root {
  --gray: #dcdcdc;
  --gray-dark: #bfbfbf;
}

.tag {
  background-color: var(--gray);
  border-radius: 10px;
  padding: 3px 6px;
}

.darker {
  background-color: var(--gray-dark);
}

/*# sourceMappingURL=index.705598b8.css.map */

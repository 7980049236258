:root {
  --gray: #dcdcdc;
  --gray-dark: #bfbfbf;
}

.tag {
  background-color: var(--gray);

  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;

  border-radius: 10px;
}

.darker {
  background-color: var(--gray-dark);
}
